import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { MillbrookAPI } from './Infrastructure/MillbrookAPI';

export class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true };
    }

    renderContent() {

        if (this.state.next === "users") {
            return (<Redirect to="/users" />);
        } else {
            return (<Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {
                    if (await MillbrookAPI.SignIn(fields.email, fields.password, error => {

                        if (error === "too_many_attempts") {
                            setFieldError("email", "Too many attempts, please wait 5 minutes before trying again");
                        } else if (error === "account_locked") {
                            setFieldError("email", "Your account is locked, please contact concierge to unlock");
                        } else {
                            setFieldError("email", "Invalid email or password");
                        }

                    })) {

                        var current = await MillbrookAPI.GetUserDetails("current");
                        if(current.data.role == 2){
                            this.setState({ next: "users" });
                        } else {
                            MillbrookAPI.LogOut();
                        }
                    }
                }}
                render={({ errors, status, touched, isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <Field name="email" placeholder="Email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <Field name="password" placeholder="Password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn mr-2 btn-outline-light mt-2" disabled={isSubmitting} style={{ width: "100%" }}>
                                {!isSubmitting && "SIGN IN"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
                )}
            />);
        }
    }

    render() {

        return (
            <div className="auth-content">
                <div className="text-white">
                    <div className="card-body text-center">

                        <div className="mb-4">
                            <i className="feather icon-unlock auth-icon"></i>
                        </div>
                        <h3 className="mb-4 text-white">Sign in</h3>
                        <hr />
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        );

    }
}