import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch, GetAPIHeadersTokenOnly } from "./Helper";

export class ApiResponse {

    constructor() {
        this.successful = false;
        this.authenticated = false;
    }

    static async Create(response: Response) {
        var result = new ApiResponse();

        result.authenticated = true;

        if (!response.ok) {
            result.successful = false;

            if (response.status === 401) {
                MillbrookAPI.LogOut();
                result.authenticated = false;
                window.location.reload();
            } else {
                try {
                    var data = await response.json();
                    result.data = data;
                    result.validationErrors = data.validationErrors;
                } catch (ex) {
                    console.log("No validate errors for this request");
                }
            }
        } else {
            result.successful = true;
            try {
                result.data = await response.json();
            } catch (ex) {
                console.log(ex);
                console.log("No content for this request");
            }
        }

        return result;
    }

    successful: Boolean;
    validationErrors: any;
    authenticated: Boolean;
    data: any;
}

export class ListQueryParams {

    constructor() {
        this.skip = 0;
        this.take = 50;
        this.direction = "ascending"
        this.params = Array<QueryParameter>();
    }

    Sort(sort: string, direction?: string) {
        this.sort = sort;

        if (direction && direction === "desc")
            return this.Descending();
        if (direction && direction !== "desc")
            return this.Ascending();

        return this;
    }

    Descending() {
        this.direction = "descending";
        return this;
    }

    Ascending() {
        this.direction = "ascending";
        return this;
    }

    Paginate(skip: number, take: number) {
        this.skip = skip;
        this.take = take;
        return this;
    }

    Search(value: string) {
        this.WithParam("search", value);
        return this;
    }

    WithStart(value: any) {
        this.WithParam("start", value);
        return this;
    }

    WithEnd(value: any) {
        this.WithParam("end", value);
        return this;
    }

    WithParam(param: string, value: any) {

        this.params.push({
            key: param,
            value: value
        });

        return this;
    }

    skip: number;
    take: number;
    direction: string;
    params: QueryParameter[];
    sort?: string;

    GenerateQueryString() {
        var query = "?skip=" + this.skip + "&take=" + this.take;
        if (this.sort)
            query = query + "&sort=" + this.sort + "&direction=" + this.direction;

        if (this.params) {
            this.params.map(param => {
                if (param.value && param.key) {
                    if (param.value instanceof Date) {
                        query = query + "&" + param.key + "=" + (<Date>param.value).toISOString();
                    } else {
                        query = query + "&" + param.key + "=" + param.value;
                    }
                }
            });
        }

        return query;
    }
}

export class QueryParameter {
    key?: string;
    value?: any;
}

export class UserQueryParams extends ListQueryParams {

    WithRole(value: number) {
        this.WithParam("role", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithUsedApp(value: Boolean) {
        this.WithParam("usedApp", value);
        return this;
    }

}

export class InvitationQueryParams extends ListQueryParams {

    WithExpired(value: Boolean) {
        this.WithParam("expired", value);
        return this;
    }

    WithUsed(value: Boolean) {
        this.WithParam("used", value);
        return this;
    }

    WithStatus(value: number) {
        this.WithParam("status", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

}

export class MillbrookAPI {


    //******************************
    // Invitations
    //******************************

    static async GetInvitations(params: InvitationQueryParams) {

        var query = (await GetAPIDomain()) + "/invitations" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    static async GetInvitationDetails(id: string) {

        var query = (await GetAPIDomain()) + "/invitations/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    static async ResendInvitation(userId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + userId + "/invitations", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async RevokeInvitation(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invitations/" + id + "/revoke", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async CreateInvitation(name: string, mobile: string, membershipNumber: string, email: string, userTypeId: string, role: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invitations/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "mobile": mobile,
                "membershipNumber": membershipNumber,
                "email": email,
                "userTypeId": userTypeId,
                "role": parseInt(role)
            })
        }));
    }

    static async SetUserPassword(id: string, password: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + `/users/${id}/set-password`, {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "password": password
            })
        }));
        
    }

    static async InvitationsBulkUpload(file: any, userTypeId: string) {

        var formData = new FormData();
        formData.append('userTypeId', userTypeId);
        formData.append('file', file); 

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/uploads/users", {
            method: 'post',
            headers: await GetAPIHeadersTokenOnly(),
            body: formData
        }));
    }



    //******************************
    // User types
    //******************************

    static async GetUserTypes(params: ListQueryParams) {

        var query = (await GetAPIDomain()) + "/user-types" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    static async GetUserTypeDetails(id: string) {

        var query = (await GetAPIDomain()) + "/user-types/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    static async CreateUserType(name: string, backgroundColour: string, foregroundColour: string, termsLink: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/user-types/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "backgroundColour": backgroundColour,
                "foregroundColour": foregroundColour,
                "termsLink": termsLink
            })
        }));
    }

    static async UpdateUserType(id: string, name: string, backgroundColour: string, foregroundColour: string, termsLink: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/user-types/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "backgroundColour",
                "value": backgroundColour
            }, {
                "op": "replace",
                "path": "foregroundColour",
                "value": foregroundColour
            }, {
                "op": "replace",
                "path": "termsLink",
                "value": termsLink
            }])
        }));
    }

    //******************************
    // Users
    //******************************

    static async GetUsers(params: UserQueryParams) {

        var query = (await GetAPIDomain()) + "/users" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    static async GetUserDetails(id: string) {

        var query = (await GetAPIDomain()) + "/users/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    static async CreateUser(name: string, mobile: string, membershipNumber: string, email: string, userTypeId: string, role: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "mobile": mobile,
                "membershipNumber": membershipNumber,
                "email": email,
                "userTypeId": userTypeId,
                "role": parseInt(role)
            })
        }));
    }


    static async UpdateUser(id: string, name: string, mobile: string, membershipNumber: string, email: string, userTypeId: string, role: number) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "mobile",
                "value": mobile
            }, {
                "op": "replace",
                "path": "membershipNumber",
                "value": membershipNumber
            }, {
                "op": "replace",
                "path": "email",
                "value": email
            }, {
                "op": "replace",
                "path": "role",
                "value": role
            }, {
                "op": "replace",
                "path": "userTypeId",
                "value": userTypeId
            }])
        }));
    }

    //******************************
    // AUTHENTICATION
    //******************************

    static refresh_lock = false;

    //SIGN IN
    static async SignIn(username: string, password: string, setError?: Function) {

        let formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('client_id', '7f675b52-c4f1-4b35-b268-414cd4e0636e');
        formData.append('grant_type', 'password');

        const response = await fetch((await GetAPIDomain()) + "/connect/token", {
            body: formData,
            method: "post"
        });

        if (!response.ok) {

            localStorage.clear();

            if (setError) {
                const data = await response.json();
                setError(data.error_description);
            }

            return false;
        }

        const data = await response.json();

        localStorage.setItem("acess-token", data.access_token);
        localStorage.setItem("refresh-token", data.refresh_token);
        localStorage.setItem("expires", new Date((new Date()).getTime() + (50 * 60 * 1000)).toISOString());

        return true;
    }

    //REFRESH TOKEN
    static async RefreshToken() {

        if (!this.refresh_lock) {
            this.refresh_lock = true;

            var token = localStorage.getItem("refresh-token");

            if (!token) {
                localStorage.clear();
                return;
            }

            let formData = new URLSearchParams();
            formData.append('refresh_token', token);
            formData.append('client_id', '7f675b52-c4f1-4b35-b268-414cd4e0636e');
            formData.append('grant_type', 'refresh_token');

            const response = await fetch((await GetAPIDomain()) + "/connect/token", {
                body: formData,
                method: "post"
            });

            if (!response.ok) {
                localStorage.clear();
                return false;
            }

            const data = await response.json();

            localStorage.setItem("acess-token", data.access_token);
            localStorage.setItem("refresh-token", data.refresh_token);
            localStorage.setItem("expires", new Date((new Date()).getTime() + (50 * 60 * 1000)).toISOString());

            this.refresh_lock = false;

            return true;
        }
    }

    //SIGN OUT
    static LogOut() {
        localStorage.removeItem("acess-token");
        localStorage.removeItem("refresh-token");
        localStorage.clear();
    }

    //IS AUTHENTICATED
    static IsAuthenticated() {

        if (localStorage == null)
            return false;

        if (localStorage.getItem("acess-token") == null)
            return false;

        var token = localStorage.getItem("acess-token");
        if (token != null && token.length === 0)
            return false;

        return true;
    }

}
